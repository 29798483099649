<template>
  <div class="course-list" v-loading="pageLoading">
    <div class="course-list-title">
      <div class="text">
        <span>课程管理</span>
        <div class="line"></div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form style="margin-top: 3.17708vw" :model="searchForm" onsubmit="return false" :inline="true">
      <el-row>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-input class="form-input" v-model="searchForm.courseName" placeholder="课程名称" clearable/>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.status" clearable placeholder="请选择状态">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.courseClassify" clearable placeholder="请选择类型">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.courseField" clearable placeholder="请选择领域">
              <el-option v-for="item in areaOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.courseSuitable" clearable placeholder="请选择阶段" @change="handleSearchStep">
              <el-option v-for="item in stepOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.courseLevel" clearable placeholder="请选择等级" @change="handleSearchLevel">
              <el-option v-for="item in levelOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item style="float: left">
            <el-button type="primary" icon="el-icon-search" @click="searchFun">查询</el-button>
          </el-form-item>
          <el-form-item style="float: left">
            <el-button icon="el-icon-refresh-right" @click="resetFun">重置</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item style="float: right">
            <el-button type="primary" @click="handleAdd" v-if="isAdd">添加</el-button>
          </el-form-item><!--v-if="permissions.includes('sys_dict_add')"-->
        </el-col>
      </el-row>
    </el-form>
    <div class="table-content" v-show="courseList.length > 0">
      <el-row>
        <el-col :span="courseSpan" v-for="(item, index) in courseList" :key="item.id" :style="{ 'margin-right': (index + 1) % courseNum != 0 ? courseRight : '0', 'margin-bottom': '1.04167vw', width: '16.666667vw' }">
          <el-card shadow="hover" :body-style="{ padding: 'undefined' }" @click.native="handleItem('detail', item)">
            <div class="course-body" :style="{ 'background-image': 'url(' + (item.coverImg ? item.coverImg : defaultImg) + ')', 'background-size': '16.666667vw 100%', 'background-repeat': 'no-repeat','background-position-x': '50%', 'background-color': 'aliceblue' , height: imgHeight }">
              <div class="age-scope-icon" v-show="item.courseSuitable">
                {{ item.courseSuitableName }}
              </div>
              <div class="age-scope-icon" v-show="item.courseLevel">
                {{ item.courseLevelName }}
              </div>
              <img class="status-icon" :src="item.status === '0' ? imgOfflineIcon : imgOnlineIcon" alt="">
            </div>
            <div class="info-body">
              <div class="title">{{ item.courseName }}</div>
              <el-tooltip class="item" effect="light" popper-class="customPopper" :content="item.description" placement="top">
                <div class="ltitle">{{ item.description }}</div>
              </el-tooltip>
              <div class="bottom">
                <el-tooltip class="item" effect="light" popper-class="customPopper" content="课程介绍内容框架设置" placement="bottom" v-if="isSetting">
                  <img v-if="isSetting" :src="settingIcon" alt="" @click.stop="handleItem('setting', item)"/>
                </el-tooltip>
                <el-divider direction="vertical" v-if="item.status === '1' && isUpDown"></el-divider>
                <el-tooltip class="item" effect="light" popper-class="customPopper" content="下架" placement="bottom" v-if="item.status === '1' && isUpDown">
                  <img :src="offlineIcon" alt="" v-if="item.status === '1' && isUpDown" @click.stop="handleItem('down', item)"/>
                </el-tooltip>
                <el-divider direction="vertical" v-if="item.status === '0' && isUpDown"></el-divider>
                <el-tooltip class="item" effect="light" popper-class="customPopper" content="上架" placement="bottom" v-if="item.status === '0' && isUpDown">
                  <img :src="onlineIcon" alt="" v-if="item.status === '0' && isUpDown" @click.stop="handleItem('up', item)"/>
                </el-tooltip>
                <el-divider direction="vertical" v-if="isUpdate"></el-divider>
                <el-tooltip class="item" effect="light" popper-class="customPopper" content="编辑" placement="bottom" v-if="isUpdate">
                  <img v-if="isUpdate" :src="editIcon" alt="" @click.stop="handleItem('edit', item)"/>
                </el-tooltip>
                <el-divider direction="vertical" v-if="isDelete"></el-divider>
                <el-tooltip class="item" effect="light" popper-class="customPopper" content="删除" placement="bottom" v-if="isDelete">
                  <img v-if="isDelete" :src="delIcon" alt="" @click.stop="handleItem('del', item)"/>
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="empty-list-content" v-show="courseList.length == 0">
      <img class="empty-img" :src="emptyImg" alt="" />
      <div class="empty-title">您还没添加任何课程</div>
      <div class="empty-ltitle">需要添加后才能执行相关操作</div>
    </div>

    <div v-if="pagination && ipagination.total > 0" class="l-pages">
      <div class="total-num">显示第{{ showNum }}条记录，共{{ ipagination.total }}条记录</div>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="ipagination.num" @size-change="handleSizeChange" :page-size="ipagination.size" :page-sizes="ipagination.pageSizes" layout="prev, pager, next, sizes" :total="ipagination.total"></el-pagination>
    </div>
    <el-dialog :title="courseTitle" :visible.sync="courseVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form class="dialog-form" :model="courseForm" ref="courseForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item label="封面图片：" class="form-choose-item">
              <el-upload class="avatar-uploader" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :accept="'.png,.jpg,.jpeg'">
                <img v-if="courseForm.coverImg" :src="courseForm.coverImg" class="avatar"/>
                <i v-if="courseForm.coverImg" class="el-icon-error delete-icon" @click.stop="delCourseImg"></i>
                <div v-else class="avatar-uploader-info">
                  <img class="avatar-uploader-icon" :src="uploadImgIcon" />
                  <div class="avatar-uploader-title">上传图片</div>
                  <div class="avatar-uploader-tip">图片尺寸比例4:3，仅限上传1张</div>
                </div>
              </el-upload>
              <!-- <div class="upload-tip">图片尺寸比例5:3，仅限上传1张</div> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="名称：" prop="courseName">
              <el-input class="form-input" v-model="courseForm.courseName" placeholder="请输入名称" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="分类：" prop="courseClassify">
              <el-select class="form-input" v-model="courseForm.courseClassify" multiple clearable placeholder="请选择分类">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="领域：" prop="courseField">
              <el-select class="form-input" v-model="courseForm.courseField" multiple clearable placeholder="请选择领域">
                <el-option v-for="item in areaOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="等级or阶段：" prop="levelOrStep">
              <el-radio-group v-model="courseForm.levelOrStep" @change="levelOrStepChange">
                <el-radio :label="'1'">等级</el-radio>
                <el-radio :label="'2'">阶段</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="courseForm.levelOrStep == '1'">
            <el-form-item class="form-choose-item" label="" prop="courseLevel">
              <el-select class="form-input" v-model="courseForm.courseLevel" multiple clearable placeholder="请选择等级">
                <el-option v-for="item in levelOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="courseForm.levelOrStep == '2'">
            <el-form-item class="form-choose-item" label="" prop="courseSuitable">
              <el-select class="form-input" v-model="courseForm.courseSuitable" multiple clearable placeholder="请选择阶段">
                <el-option v-for="item in stepOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="描述：">
              <el-input class="form-input" type="textarea" :rows="5" placeholder="请填写描述内容..." v-model="courseForm.description" resize="none" :maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="排序：" prop="sort">
              <el-input class="form-input" v-model="courseForm.sort" placeholder="请输入排序" v-input-number></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <catalog-setting :catalogSettingDialogVisible.sync="courseSettingDialogVisible" :catalogSettingDialogHandleType="handleType" :catalogSettingDialogType="courseSettingType" :formData="courseSettingData" :courseId="courseId" @refreshPage="refreshPage"></catalog-setting>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import CatalogSetting from "@/components/catalogSetting/index.vue";
import settingIcon from "@/assets/img/setting.png";
import offlineIcon from "@/assets/img/offline-icon.png";
import onlineIcon from "@/assets/img/online-icon.png";
import editIcon from "@/assets/img/edit-icon.png";
import delIcon from "@/assets/img/del-icon.png";
import uploadImgIcon from "@/assets/img/upload-img-icon.png";
import defaultImg from "@/assets/img/default-img.png";
import emptyImg from "@/assets/img/empty-img.png";
import imgOfflineIcon from "@/assets/img/img-offline-icon.png";
import imgOnlineIcon from "@/assets/img/img-online-icon.png";
import { getAgeScopeName, getCourseLevelName } from "@/utils/getDicName";
import { getToken } from "@/utils/auth";
import { noSymbol } from "@/utils/validate.js";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: "courseManage",
  components: { CatalogSetting },
  data() {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error("只支持汉字、数字、字母组合"));
      } else {
        callback();
      }
    };
    return {
      pageLoading: false, //页面loading
      searchForm: {
        courseName: "",
        status: "",
        courseClassify: "",
        courseField: "",
        courseSuitable: "",
        courseLevel: "",
      }, //查询条件
      courseList: [], //课程列表
      pagination: false, //分页标识
      ipagination: {
        num: 1,
        size: 12,
        pageSizes: [12, 24, 60, 120, 600],
        total: 0,
      }, // 分页信息
      statusOptions: [
        { name: "上架", value: "1",},
        { name: "下架", value: "0",},
      ], //状态下拉
      currentDate: new Date(),
      courseTitle: "", //课程基础信息弹窗标题
      courseVisible: false, //课程基础信息弹窗显隐标识
      courseForm: {
        courseName: "",
        coverImg: "",
        courseClassify: [],
        courseField: [],
        levelOrStep: "2",
        courseLevel: [],
        courseSuitable: [],
        description: "",
        sort: "",
      }, //查询条件
      rules: {
        courseName: [
          { required: true, message: "请输入名称", trigger: "blur",},
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: "blur" },
        ],
        courseClassify: [
          { type: "array", required: true, message: "请选择分类", trigger: "change",},
        ],
        courseField: [
          { type: "array", required: true, message: "请选择领域", trigger: "change",},
        ],
        levelOrStep: [
          { required: true, message: "请选择等级or阶段", trigger: "change" },
        ],
        courseLevel: [
          { type: "array", required: true, message: "请选择等级", trigger: "change" },
        ],
        courseSuitable: [
          { type: "array", required: true, message: "请选择阶段", trigger: "change" },
        ],
        sort: [
          { required: true, message: "请输入排序", trigger: "blur",},
        ]
      },
      buttonloading: false, //弹框按钮loading
      courseSettingDialogVisible: false, //内容设置弹窗
      courseSettingData: {}, //内容设置传值
      courseSettingType: "", //内容设置类型
      courseId: null, //编辑课程id
      courseStep: 0, //步骤条变量
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },

      settingIcon: settingIcon, //内容设置按钮
      offlineIcon: offlineIcon, //下架按钮
      onlineIcon: onlineIcon, //上架按钮
      editIcon: editIcon, //修改按钮
      delIcon: delIcon, //删除按钮
      uploadImgIcon, //上传按钮
      defaultImg, //默认图片
      emptyImg, //空数据图片
      imgOfflineIcon, //下架icon
      imgOnlineIcon, //上架icon

      handleType: "", //操作类型
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list
      permissionButtonList: [], //权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      isSetting: false,
      isUpDown: false,

      courseSpan: 5, //课程宽度
      courseNum: 4, //课程个数
      courseRight: '5.2vw',
      imgHeight: '12.5vw',// 课程封面高度
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      menuList: (state) => state.common.menuList,
    }),
    showNum() {
      let start = (this.ipagination.num - 1) * this.ipagination.size + 1;
      let end = this.ipagination.num * this.ipagination.size > this.ipagination.total ? this.ipagination.total : this.ipagination.num * this.ipagination.size;
      return this.ipagination.total === 0 ? this.ipagination.total : `${start}-${end}`;
    },
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("course:update") != -1;
          this.isAdd = val.indexOf("course:save") != -1;
          this.isDelete = val.indexOf("course:delete") != -1;
          this.isSetting = val.indexOf("course:config") != -1;
          this.isUpDown = val.indexOf("course:updown") != -1
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
    stepOptions: {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.pageLoading = true;
    this.getData();
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    let that = this;
    window.onresize = function () {
      that.resizeCourseInfo();
    };
    that.resizeCourseInfo();
  },
  methods: {
    getToken,
    getAgeScopeName,
    getCourseLevelName,
    getPermissionButton,
    //调整video个数和宽度
    resizeCourseInfo() {
      console.log(window.outerWidth);
      if (window.outerWidth <= 1800 && window.outerWidth > 1600) {
        this.courseRight = '4.3vw'
      } else if (window.outerWidth <= 1600 && window.outerWidth > 1500) {
        this.courseRight = '4vw'
      } else if (window.outerWidth <= 1500 && window.outerWidth > 1400) {
        this.courseRight = '3.2vw'
      } else if (window.outerWidth <= 1400 && window.outerWidth > 1370) {
        this.courseRight = '3vw'
      } else if (window.outerWidth <= 1370 && window.outerWidth > 1300) {
        this.courseRight = '2.7vw'
      } else if (window.outerWidth <= 1300) {
        this.courseRight = '2.2vw'
      } else if (window.outerWidth > 1800) {
        this.courseRight = '5.2vw'
      }
    },
    //获取课程列表
    getData() {
      let params = { ...this.searchForm };
      params.current = this.ipagination.num;
      params.size = this.ipagination.size;
      this.$api.getCourseList(params).then((res) => {
        this.pageLoading = false;
        if (res.data.code === 0) {
          res.data.data.records.map((item) => {
            if(item.courseSuitable){
              let courseSuitableList = item.courseSuitable.split(",")
              let courseSuitableNameList = []
              courseSuitableList.map((info) => {
                courseSuitableNameList.push(this.getAgeScopeName(info))
              })
              item.courseSuitableName = courseSuitableNameList.join(",")
            }
            if(item.courseLevel){
              let courseLevelList = item.courseLevel.split(",")
              let courseLevelNameList = []
              courseLevelList.map((info) => {
                courseLevelNameList.push(this.getCourseLevelName(info))
              })
              item.courseLevelName = courseLevelNameList.join(",")
            }
          })
          this.courseList = res.data.data.records; //.concat(res.data.data.records).concat(res.data.data.records).concat(res.data.data.records).concat(res.data.data.records)
          this.ipagination.total = res.data.data.total;
          if (res.data.data.total > 0) {
            this.pagination = true;
          } else {
            this.pagination = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //阶段改变方法
    handleSearchStep(val){
      this.searchForm.courseSuitable = val
      this.searchForm.courseLevel = ""
    },
    //等级改变方法
    handleSearchLevel(val){
      this.searchForm.courseLevel = val
      this.searchForm.courseSuitable = ""
    },
    //查询
    searchFun() {
      this.pageLoading = true;
      this.ipagination.num = 1;
      this.getData();
    },
    //重置
    resetFun() {
      this.pageLoading = true;
      this.searchForm = {
        courseName: "",
        status: "",
        courseClassify: "",
        courseField: "",
        courseSuitable: "",
        courseLevel: "",
      };
      this.ipagination.num = 1;
      this.getData();
    },
    //上传封面成功事件
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.courseForm.coverImg = res;
      this.pageLoading = false
    },
    //上传封面之前事件
    beforeAvatarUpload(file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if(isJPG && isLt2M){
        this.pageLoading = true
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除图片
    delCourseImg() {
      //"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该封面图片？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该封面图片，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.courseForm.coverImg = "";
      }).catch(() => {});
    },
    //添加课程
    handleAdd() {
      this.courseVisible = true;
      this.handleType = "add";
      this.$nextTick(() => {
        this.courseTitle = "添加";
      });
    },
    //操作事件
    handleItem(type, row) {
      switch (type) {
        case "edit":
          this.courseVisible = true;
          this.handleType = "edit";
          this.$nextTick(() => {
            this.courseTitle = "编辑";
            this.courseId = row.id;
            this.courseForm.courseName = row.courseName;
            this.courseForm.coverImg = row.coverImg;
            this.courseForm.courseClassify = row.courseClassify.split(",");
            this.courseForm.courseField = row.courseField.split(",");
            this.courseForm.levelOrStep = row.courseLevel !== "" && row.courseSuitable !== "" ? "2" : row.courseLevel ? "1" : row.courseSuitable ? "2" : "";
            this.courseForm.courseSuitable = row.courseSuitable.split(",");
            this.courseForm.courseLevel = row.courseLevel.split(",");
            this.courseForm.description = row.description;
            this.courseForm.sort = row.sort
          });
          break;
        case "detail":
          this.$router.push({ path: "/courseManage/edit", query: { id: row.id }, });
          break;
        case "setting":
          this.courseSettingDialogVisible = true;
          this.handleType = "edit";
          this.$nextTick(() => {
            this.courseSettingType = "introduce";
            this.courseId = row.id;
          });
          break;
        case "up":
          this.changeStatus("1", row);
          break;
        case "down":
          this.changeStatus("0", row);
          break;
        case "del":
          this.deleteCourse(row.id);
          break;
      }
    },
    //上下架
    changeStatus(type, row) {
      this.$api.courseHandle("/api/educourse", { id: row.id, courseName: row.courseName, status: type }, "edit").then((res) => {
        if (res.data.code === 0) {
          this.$message({ message: type == "1" ? "上架成功" : "下架成功", type: "success", duration: 2000,onClose: () => {
            this.pageLoading = true;
            this.getData();
          }, });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除
    deleteCourse(id) {
      //"确定删除该课程, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该课程？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该课程，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.$api.delCourse({ id: id, delFlag: "1" }).then((res) => {
          if (res.data.code === 0) {
            this.$message({ type: "success", message: "删除成功!", duration: 2000, onClose: () => {
              this.pageLoading = true;
              this.getData();
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => {});
    },
    //等级or阶段改变
    levelOrStepChange(val) {
      this.courseForm.levelOrStep = val;
      let propName = "";
      this.courseForm.courseLevel = [];
      this.courseForm.courseSuitable = [];
      if (val == "1") {
        propName = "courseLevel";
      } else if (val == "2") {
        propName = "courseSuitable";
      }
      this.$refs.courseForm.clearValidate(propName);
    },
    //页码改变
    handleCurrentChange(val) {
      this.ipagination.num = val;
      this.getData();
    },
    //每页大小改变
    handleSizeChange(size) {
      this.ipagination.size = size;
      this.ipagination.num = 1;
      this.getData();
    },
    //处理翻页
    handleStep(type) {
      if (type == "next") {
        this.submitBase();
      } else if (type == "prev") {
        --this.courseStep;
        this.courseSettingType = "";
        this.courseSettingData = {};
      }
    },
    //弹框关闭
    handleClose() {
      this.courseVisible = false;
      this.$nextTick(() => {
        this.courseForm = {
          courseName: "",
          coverImg: "",
          courseClassify: [],
          courseField: [],
          levelOrStep: "2",
          courseLevel: [],
          courseSuitable: [],
          description: "",
          sort: ""
        };
        this.courseTitle = "";
        this.$refs.courseForm.clearValidate();
      });
    },
    //基础信息提交
    submitBase() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            courseName: this.courseForm.courseName,
            coverImg: this.courseForm.coverImg,
            courseClassify: this.courseForm.courseClassify.join(","),
            courseField: this.courseForm.courseField.join(","),
            courseLevel: this.courseForm.courseLevel.join(","),
            courseSuitable: this.courseForm.courseSuitable.join(","),
            description: this.courseForm.description,
            sort: this.courseForm.sort
          };
          let url = "";
          if (this.handleType == "add") {
            url = "/api/educourse/save";
          } else {
            params.id = this.courseId;
            url = "/api/educourse";
          }
          this.$api.courseHandle(url, params, this.handleType).then((res) => {
            if (res.data.code === 0) {
              this.$message({ message: "保存成功", type: "success", duration: 2000, onClose: () => {
                this.buttonloading = false;
                // if (this.handleType == "add") {
                //   this.courseSettingDialogVisible = true;
                //   this.$nextTick(() => {
                //     this.courseId = res.data.data;
                //     this.courseSettingType = "introduce";
                //   });
                // } else {
                  this.pageLoading = true;
                  this.getData();
                // }
                this.handleClose();
              }, });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹窗保存
    handleSubmit() {
      this.submitBase();
    },
    //刷新列表
    refreshPage(type) {
      this.courseSettingDialogVisible = false;
      this.$nextTick(() => {
        this.courseId = null;
        this.courseSettingType = "";
        this.handleType = "";
        this.pageLoading = true;
        this.getData();
      });
    },
  },
  beforeDestroy() {
    window.onresize = null;
  },
};
</script>
<style scoped lang="scss">
.course-list {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
  position: relative;
  .course-list-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.3);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
/deep/.el-textarea{
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover{
    border-color: #c0c4cc;
  }
}
/deep/.el-textarea__inner{
  margin-bottom: 18px;
  border: none;
}
/deep/.el-input__count {
  bottom: 10px;
  line-height: normal;
  &::before {
    content: "已输入 ";
  }
}
</style>